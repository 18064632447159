<div class="dark">
  <app-navbar></app-navbar>
  <section>
    <div class="context-container">
      <div *ngIf="objectData && objectData.published" class="note">
        Вы не сможете изменить объект, пока он опубликован и доступен в общей сети. Если требуется внести изменения,
        пожалуйста, обратитесь к администратору.</div>
      <form [formGroup]="objectForm" class="form">

        <div class="row form-item">
          <p class="col-4">{{'object-edit.language' | translate}}</p>
          <div class="col-8 form-input">
            <select formControlName="languageControl">
              <option *ngFor="let lang of langs" [value]="lang">
                {{
                lang === "ru"
                ? "Русский"
                : lang === "by"
                ? "Белорусский"
                : "Русский и Белорусский"
                }}
              </option>
            </select>
          </div>
        </div>
        <mat-spinner [diameter]="30" style="margin-bottom: 40px;" *ngIf="getlanguageContext"></mat-spinner>

        <div formGroupName="objectHeader">
          <div class="section-title">{{ 'object-edit.header-section.header' | translate }}</div>

          <div class="row form-item">
            <p class="col-4">{{ 'object-edit.header-section.name' | translate }}</p>
            <div class="col-8 form-input">
              <input type="text" formControlName="name" />
            </div>
          </div>

          <div class="row form-item">
            <p class="col-4">{{ 'object-edit.header-section.start-date' | translate }}</p>
            <div class="col-8 form-input">
              <input matInput [matDatepicker]="picker1" formControlName="startDate" readonly="true"
                placeholder="Выберите начальную дату" />
              <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </div>
          </div>

          <div class="row form-item">
            <p class="col-4">{{ 'object-edit.header-section.end-date' | translate }}</p>
            <div class="col-8 form-input">
              <input matInput [matDatepicker]="picker2" formControlName="endDate" readonly="true"
                placeholder="Выберите конечную дату" />
              <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </div>
          </div>

          <div class="row form-item">
            <p class="col-4">{{ 'object-edit.header-section.title' | translate }}</p>
            <div class="col-8 form-input">
              <input type="text" formControlName="title" />
            </div>
          </div>

          <div class="row form-item" style="display: flex; align-items: start;">
            <p class="col-4">{{ 'object-edit.header-section.description' | translate }}</p>
            <div class="col-8 form-input">
              <quill-editor format="html" formControlName="descriptionHtml"></quill-editor>
            </div>
          </div>
        </div>

        <div class="section-title">{{ 'object-edit.links-section.header' | translate }}</div>
        <div formArrayName="objectLinks">
          <div *ngFor="let linkGroup of objectLinksArray.controls; let i = index" [formGroupName]="i"
            class="link-section">
            <div class="row form-item">
              <p class="col-4">{{ 'object-edit.links-section.title' | translate }}</p>
              <div class="col-8 form-input">
                <input type="text" formControlName="title" />
              </div>
            </div>

            <div class="row form-item">
              <p class="col-4">{{ 'object-edit.links-section.name' | translate }}</p>
              <div class="col-8 form-input">
                <input type="text" formControlName="linkName" />
              </div>
            </div>

            <div class="row form-item">
              <p class="col-4">{{ 'object-edit.links-section.source' | translate }}</p>
              <div class="col-8 form-input">
                <input type="text" formControlName="linkSource" />
              </div>
            </div>

            <div class="row form-item">
              <p class="col-4">{{ 'object-edit.links-section.description' | translate }}</p>
              <div class="col-8 form-input">
                <textarea type="text" formControlName="description"></textarea>
              </div>
            </div>
            <button class="remove-link" (click)="removeLinksSection(i)">
              -
            </button>
          </div>
        </div>
        <div class="form-item d-flex justify-content-end pe-4">
          <button class="add-link" (click)="addLinksSection()">+</button>
        </div>

        <div class="section-title">{{ 'object-edit.location-section.header' | translate }}</div>
        <div formGroupName="objectLocation">
          <div class="row form-item">
            <p class="col-4">{{ 'object-edit.location-section.title' | translate }}</p>
            <div class="col-8 form-input">
              <input type="text" formControlName="title" />
            </div>
          </div>

          <div class="row form-item">
            <p class="col-4">{{ 'object-edit.location-section.description' | translate }}</p>
            <div class="col-8 form-input">
              <textarea type="text" formControlName="description"></textarea>
            </div>
          </div>
        </div>

        <div class="row form-item">
          <p class="col-4">Заголовок</p>
          <div class="col-8 form-input">
            <div>Координаты:</div>
          </div>
        </div>

        <div class="row form-item">
          <p class="col-4">Описание</p>
          <div class="col-8 form-input">
            <div>{{ coordinates }}</div>
          </div>
        </div>
        <div class="map-container">
          <div id="map">
            <ya-map #map [center]="coordinates" [zoom]="zoom" (ready)="onMapReady($event)">
              <ya-placemark [geometry]="coordinates" [properties]="placemarkProperties"
                [options]="placemarkOptions"></ya-placemark>
            </ya-map>
          </div>
        </div>
      </form>
      <div class="drag-n-drop">
        <app-drag-n-drop [files]="objectFiles" [uuid]="uuid"></app-drag-n-drop>
      </div>
      <div class="submit-container mt-3">
        <button class="submit" (click)="updateObjectAndContextButton()">
          Подтвердить
        </button>
        <mat-spinner [diameter]="30" style="margin: 0 20px;" *ngIf="isUploadingFiles"></mat-spinner>
        <div class="submit-progress-message">{{updatingFilesMessage}}</div>
      </div>
    </div>
  </section>
</div>

<div class="light">
  <app-footer></app-footer>
</div>