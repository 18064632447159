import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DragAndDropDirective } from '../../directives/drag-and-drop.directive';
import { ManagementService } from '../../services/management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ObjectService } from '../../services/object.service';
import { MediaContext } from '../../models/media-context';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-drag-n-drop',
  standalone: true,
  imports: [CommonModule, DragAndDropDirective],
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss'],
})
export class DragNDropComponent implements OnChanges {
  backendUrl: string = environment.backendUrl.replace('/api', '');
  @Input() files: any[];
  @Input() uuid: string;

  mediaUrls: any[] = [];

  private readonly MAX_FILE_SIZE_MB = 30 * 1024 * 1024;

  constructor(
    private managementService: ManagementService,
    private notification: MatSnackBar,
    private http: HttpClient,
    private objectService: ObjectService,
    public router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files'] && changes['files'].currentValue) {
      this.loadMediaUrlsOneByOne(0);
    }
  }

  onFileDropped($event: FileList) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      this.prepareFilesList(target.files);
    }
  }

  deleteFile(index: number, fileUrl?: string) {
    if (typeof fileUrl === 'string') {
      this.deleteFromServerByUuidAndFilename(this.uuid, fileUrl, index);
    } else {
      this.files.splice(index, 1);
    }
  }

  deleteFromServerByUuidAndFilename(
    uuid: string,
    fileUrl: string,
    index: number
  ) {
    const filename = this.getFilenameFromUrl(fileUrl);
    this.managementService
      .deleteFileByUuidAndFilename(uuid, filename)
      .subscribe(
        (res) => {
          this.files.splice(index, 1);
          this.notification.open(`Файл ${filename} был удален!`, 'OK', {
            duration: 5000,
          });
        },
        (error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.notification.open(
              `Недостаточно прав для удаления файла ${filename}. Обратитесь к администрации`,
              'OK',
              { duration: 5000 }
            );
          } else {
            this.notification.open(
              `Произошла ошибка при удалении файла ${filename}`,
              'OK',
              { duration: 5000 }
            );
          }
        }
      );
  }

  uploadFilesSimulator(index: number) {
    if (index >= this.files.length) {
      return;
    }

    const file = this.files[index];
    const progressInterval = setInterval(() => {
      if (file.progress >= 100) {
        clearInterval(progressInterval);
        this.uploadFilesSimulator(index + 1);
      } else {
        if (file.progress) {
          file.progress += 5;
        }
      }
    }, 200);
  }

  prepareFilesList(files: FileList) {
    const fileArray = Array.from(files);

    fileArray.forEach((file) => {
      if (file.size > this.MAX_FILE_SIZE_MB) {
        this.notification.open(
          `Файл ${file.name} превышает допустимый размер 30 МБ и не был добавлен.`,
          'OK',
          { duration: 5000 }
        );
      } else {
        file['progress'] = 0;
        this.files.push(file);
      }
    });
    this.uploadFilesSimulator(0);
  }

  formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFilenameFromUrl(fileUrl: string) {
    const lastIndex = fileUrl.split('/').length - 1;
    let filename = fileUrl.split('/')[lastIndex];
    return filename;
  }

  downloadFile(file: any) {
    let url: string = '';
    if (typeof file === 'string') {
      this.downloadFileByUrl(file);
    } else {
      url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  downloadFileByUrl(fileUrl: string) {
    const url = environment.backendUrl + fileUrl.replace('/api', '');
    this.http.get(url, { responseType: 'blob' }).subscribe((blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = this.getFilenameFromUrl(fileUrl);
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(objectUrl);
      document.body.removeChild(a);
    });
  }

  loadMediaUrlsOneByOne(index: number) {
    if (index >= this.files.length) {
      return;
    }

    const fullUrl =
      environment.backendUrl.replace('/api', '') + this.files[index];
    this.objectService
      .loadMediaByUrl(fullUrl)
      .subscribe((media: MediaContext) => {
        this.mediaUrls.push(media);
        this.loadMediaUrlsOneByOne(index + 1);
      });
  }

  displayImageOrFileIcon(file: string) {
    const fullUrl = environment.backendUrl.replace('/api', '') + file;
    const safeUrl: MediaContext = this.mediaUrls.find(
      (url) => url.originUrl === fullUrl
    );
    if (safeUrl && safeUrl.type.includes('image')) {
      return safeUrl.url;
    }
    return 'assets/file-icon.svg';
  }
}
