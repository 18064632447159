import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectData } from '../models/object-data';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { ObjectManagement } from '../models/object-management';

@Injectable({
  providedIn: 'root',
})
export class ManagementService {
  backendUrl = environment.backendUrl;
  singleObjectUrl = this.backendUrl + '/object';
  objectsUrl = this.backendUrl + '/management';

  constructor(private http: HttpClient) {}

  getAllObjects(): Observable<ObjectManagement[]> {
    return this.http.get<ObjectManagement[]>(this.objectsUrl);
  }

  createObject(obj: ObjectManagement) {
    return this.http.post(`${this.backendUrl}/object`, obj);
  }

  deleteObject(object: ObjectManagement) {
    return this.http.delete(`${this.singleObjectUrl}/${object.uuid}`);
  }

  updateObject(obj: ObjectManagement, uuid: string) {
    return this.http.put(`${this.singleObjectUrl}/${uuid}`, obj);
  }

  uploadFiles(file: any, uuid: string) {
    let formData: FormData = new FormData();
    formData.append('uploadedFile', file);
    return this.http.post(`${this.singleObjectUrl}/${uuid}/file`, formData, {
      reportProgress: true,
      observe: 'events' as const,
    });
  }

  getObjectFiles(uuid: string): Observable<any> {
    return <any>this.http.get(`${this.singleObjectUrl}/${uuid}/files`);
  }

  deleteFileByUuidAndFilename(uuid: string, filename: string): Observable<any> {
    return <any>(
      this.http.delete(`${this.singleObjectUrl}/${uuid}/file/${filename}`)
    );
  }

  generateTemporaryAccess(object: ObjectManagement) {
    return this.http.post(
      `${this.singleObjectUrl}/${object.uuid}/temporaryAccess`,
      null
    );
  }
}
