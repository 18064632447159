import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { ObjectData } from '../models/object-data';
import { ObjectTable } from '../models/object-table';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaContext } from '../models/media-context';

@Injectable({
  providedIn: 'root',
})
export class ObjectService {
  backendUrl = environment.backendUrl;
  backendUrlWithoutApi = environment.backendUrl.replace('/api', '');
  objectsUrl = this.backendUrl + '/objects';
  objectTableUrl = this.backendUrl + '/doc';
  objectUrl = this.backendUrl + '/object';
  qrCodeUrl = this.objectUrl + '/sendQrCode';

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  getObjectByUuid(uuid: string): Observable<ObjectData> {
    return this.http.get<ObjectData>(this.objectUrl + '/' + uuid);
  }

  searchObjectByName(name: string): Observable<ObjectData[]> {
    const params = new HttpParams().set('name', name);
    return this.http.get<ObjectData[]>(this.objectsUrl, { params: params });
  }

  searchObjects(query: string): Observable<string[]> {
    if (!query.trim()) {
      return this.http.get<string[]>('assets/test-object-links.json');
    }
    return this.http
      .get<string[]>('assets/test-object-links.json')
      .pipe(
        map((objects) =>
          objects.filter((obj) =>
            obj.toLowerCase().includes(query.toLowerCase())
          )
        )
      );
  }

  getFirstTenObjects(): Observable<string[]> {
    return this.http.get<string[]>('assets/test-object-links.json').pipe(
      map((objects) => {
        return objects.slice(0, 10);
      })
    );
  }

  getAllObjectsData(): Observable<ObjectData[]> {
    return this.http.get<ObjectData[]>(this.objectsUrl);
  }

  getObjectContextById(
    uuid: string,
    language: string
  ): Observable<ObjectTable[]> {
    const params: HttpParams = new HttpParams().set('language', language);
    return this.http.get<ObjectTable[]>(`${this.objectTableUrl}/${uuid}`, {
      params: params,
    });
  }

  getImageLinksByUuid(uuid: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.objectUrl}/${uuid}/images`);
  }

  sendQrCode(data: any) {
    return this.http.post(this.qrCodeUrl, {
      title: data.title,
      name: data.name,
      description: data.description,
      objectUuid: data.objectUuid,
      url: data.url,
    });
  }

  loadMediaByUrl(fullUrl: string): Observable<MediaContext> {
    return this.http.get(fullUrl, { responseType: 'blob' }).pipe(
      map((blob) => {
        const objectURL = URL.createObjectURL(blob);
        const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        const context: MediaContext = {
          url: safeUrl,
          type: blob.type,
          originUrl: fullUrl,
        };
        return context;
      })
    );
  }

  uploadObjectDataAndContext(
    uuid: string,
    context: any[],
    language: string,
    object: ObjectData
  ): Observable<any> {
    const params: HttpParams = new HttpParams().set('language', language);
    return this.http.put<any>(
      `${this.objectUrl}/${uuid}/edit`,
      { object: object, context: context },
      { params: params, reportProgress: true, observe: 'events' as const }
    );
  }
}
